import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Image from "../../components/image"
import Moment from "react-moment"
import Layout from "../../components/layout"
import Social from "../../images/linkedin.png"
import Markdown from "react-markdown"
import Seo from "../../components/seo"
import remarkGfm from "remark-gfm"
import * as moment from "moment"

export const query = graphql`
  query BlogQuery($slug: String!) {
    strapi {
      blogs(where: { slug: $slug }) {
        title
        slug
        id
        content
        category
        authorNameLink {
          id
          newTab
          text
          url
        }
        authorPicture {
          id
          mime
          name
          url
        }
        image {
          alternativeText
          mime
          id
          name
          url
        }
        updated_at
        published_at
        metadata {
          ukAddress
          twitterUsername
          structuredData
          twitterCardType
          metaTitle
          metaDescription
          indiaAddress
          id
          faqData
          australiaAddress
          shareImage {
            id
            mime
            url
          }
        }
        desc
      }
    }
  }
`

const Blog = ({ data }) => {
  // const { content, ...blog } = data.strapi.blogs[0]
  const [date, setDate] = useState(true)

  const [difference, setDifference] = useState(0)

  const { ...blog } = data.strapi.blogs[0]

  useEffect(() => {
    const getDifference = moment(blog.updated_at, "YYYY-MM-DD").diff(
      moment(blog.published_at, "YYYY-MM-DD"),
      "days"
    )

    setDifference(getDifference)
  }, [])
  // Function to extract headers from the Markdown content
  // const getHeadings = content => {
  //   const headingRegex = /#{1,6}\s(.+)/g
  //   const headings = []
  //   let match
  //   while ((match = headingRegex.exec(content))) {
  //     headings.push(match[1])
  //   }
  //   return headings
  // }
  // const headings = getHeadings(content)
  // function flatten(text, child) {
  //   return typeof child === 'string'
  //     ? text + child
  //     : React.Children.toArray(child.props.children).reduce(flatten, text)
  // }
  // function HeadingRenderer(props) {
  //   var children = React.Children.toArray(props.children)
  //   var text = children.reduce(flatten, '')
  //   var slug = text.toLowerCase().trim()
  //   return React.createElement('h' + props.level, {id: slug}, props.children)
  // }
  // const backgroundImgStyle = renderHeroBackgroundImage(data.heroBackgroundImage)
  return (
    <Layout>
      <Seo title="blogs" seo={blog.metadata} />
      
      <div className="relative table-css bg-[#f5f5f5] ">
        <div className="mb-8 bg-overlay z-0  h-[100px]  bg-gradient-to-r from-sofyrusPrimary via-sofyrusPrimary to-white">
          <div className="color-overlay"></div>
        </div>

        <div className="container  ">
          {/* <div className="w-11/12 md:w-8/12 lg:w-8/12 sm:w-8/12 align-center m-auto">
        {headings.length > 0 && (
          <div>
            <h3>Table of Contents</h3>
            <ul>
              {headings.map((heading, index) => (
                <li key={index}>
                  <a href={`#${heading.toLowerCase()}`}>
                    {heading}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div> */}
          <div className="w-11/12 bg-white  rounded-sm md:w-9/12 lg:w-10/12 sm:w-10/12 align-center m-auto md:px-16  lg:px-16 px-4 py-4">
            <div className=" mb-8">
              <h2 className="z-50  lg:leading-[52px!important]  px-2 font-bold lg:text-4xl md:text-4xl sm:text-2xl text-2xl text-black py-4 opacity-100">
                {blog.title}
              </h2>
              {/* <div className="flex gap-4 items-center justify-center">
                  <Image
                    media={blog.authorPicture}
                    className="w-12 h-12 rounded-full object-cover object-center"
                  />
                  <div>
                    <p
                      className="text-black text-lg font-semibold"
                      href="#"
                      rel="noreferrer"
                    >
                      {blog.authorNameLink.text}
                    </p>
                    <p className="font-medium text-sm text-[#333]">
                      <Moment format="MMM Do YYYY">{blog.updated_at}</Moment>
                    </p>
                  </div>
                </div> */}
              <div className="flex gap-4 items-center justify-between mb-4">
                
                {/* <div>
                  <a
                    className="text-black text-base md:text-xl sm:text-xl font-semibold"
                    href="https://sofyrus.com/blog"
                    rel="noreferrer"
                    target={blog.authorNameLink.newTab ? "_blank" : "_self"}
                  >
                    {blog.category}
                  </a>
                </div> */}
              </div>
              <Image
                media={blog.image}
                className="w-full rounded-lg  object-contain"
              />
            </div>
            <div className="font-medium text-lg text-gray-800 pb-8 text-clean text-justify blog-heading">
              <Markdown
                children={blog.content}
                remarkPlugins={[remarkGfm]}
                // components={{
                //   h1: HeadingRenderer,
                //   h2: HeadingRenderer,
                //   h3: HeadingRenderer,
                //   h4: HeadingRenderer,
                //   h5: HeadingRenderer,
                //   h6: HeadingRenderer,
                // }}
              />
            </div>

            {date && difference > 7 ? (
              <div>
                <p className="font-medium text-md lg:text-lg  md:text-lg sm:text-md text-black pb-8">
                  This article was original published at this website on{" "}
                  <span className="text-sofyrusPrimary font-bold">
                    <Moment format="MMM Do YYYY">{blog.published_at}</Moment>
                  </span>{" "}
                  and was recently updated on{" "}
                  <span className="text-sofyrusPrimary font-bold">
                    <Moment format="MMM Do YYYY">{blog.updated_at}</Moment>
                  </span>
                  .
                </p>
              </div>
            ) : null}
            
          </div>
          <div className="flex lg:flex-nowrap  md:w-9/12 lg:w-10/12 sm:w-10/12 bg-white md:flex-nowrap flex-wrap m-auto items-start justify-center flex-row lg:gap-12 gap-4 md:gap-8 sm:gap-4  mt-12 rounded-xs lg:px-12 px-6 md:px-8 lg:py-12 py-4 md:py-8">
              <div className="w-4/12">
                <Image
                  media={blog.authorPicture}
                  className="w-full h-full rounded-f object-cover object-center"
                />
              </div>
              <div className="uk-width-expand items-left gap-8 lg:w-8/12 sm:w-full">
                <a
                  className="text-black text-2xl font-semibold"
                  href="#"
                  rel="noreferrer"
                >
                  {blog.authorNameLink.text}
                </a>
                <p className="mt-2 mb-4 text-base">{blog.desc}</p>
                <a
                  href={blog.authorNameLink.url}
                  rel="noreferrer"
                  target={blog.authorNameLink.newTab ? "_blank" : "_self"}
                >
                  <img
                    className="w-8 h-8 rounded-f object-cover object-center"
                    src={Social}
                    alt="agile software development company"
                  />
                </a>
              </div>
            </div>
        </div>
      </div>
    </Layout>
  )
}

// const  renderBackgroundImage = (background)=>{
//   if(background)
//     return "url("+(background.url)+")";
//   return "";
// }

export default Blog
